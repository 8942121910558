*,
::after,
::before {
    box-sizing: border-box
}

body {
    margin: 0;
    padding: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

#root {
    position: relative;
}

#root,
#root > div {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

body.canScroll #root,
body.canScroll #root > div {
    overflow: unset;
}

/**
    Special case for QA Test automations where Loading Backdrop animation delay causes elements to be blocked in firefox
 */
body.nonBlockingBackdrop .MuiDialog-root:not([class*="FormDialog"]) {
    pointer-events: none;
}

body.nonBlockingBackdrop .MuiDialog-root[class*="FormDialog-root"] {
    z-index: 1301 !important;
}

div:focus {
    outline: none;
}

/*div[role=gridcell] {*/
/*    overflow: auto !important;*/
/*}*/


.animate__animated {
    animation-delay: var(--animate-delay);
}

/* Preloader Animations */
@-webkit-keyframes ytp-spinner-linspin {
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes ytp-spinner-linspin {
    to {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes ytp-spinner-easespin {
    12.5% {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        -moz-transform: rotate(405deg);
        -ms-transform: rotate(405deg);
        -o-transform: rotate(405deg);
        transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
        -moz-transform: rotate(540deg);
        -ms-transform: rotate(540deg);
        -o-transform: rotate(540deg);
        transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        -moz-transform: rotate(675deg);
        -ms-transform: rotate(675deg);
        -o-transform: rotate(675deg);
        transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
        -moz-transform: rotate(810deg);
        -ms-transform: rotate(810deg);
        -o-transform: rotate(810deg);
        transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        -moz-transform: rotate(945deg);
        -ms-transform: rotate(945deg);
        -o-transform: rotate(945deg);
        transform: rotate(945deg);
    }

    to {
        -webkit-transform: rotate(1080deg);
        -moz-transform: rotate(1080deg);
        -ms-transform: rotate(1080deg);
        -o-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@keyframes ytp-spinner-easespin {
    12.5% {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
    }

    25% {
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
    }

    37.5% {
        -webkit-transform: rotate(405deg);
        -moz-transform: rotate(405deg);
        -ms-transform: rotate(405deg);
        -o-transform: rotate(405deg);
        transform: rotate(405deg);
    }

    50% {
        -webkit-transform: rotate(540deg);
        -moz-transform: rotate(540deg);
        -ms-transform: rotate(540deg);
        -o-transform: rotate(540deg);
        transform: rotate(540deg);
    }

    62.5% {
        -webkit-transform: rotate(675deg);
        -moz-transform: rotate(675deg);
        -ms-transform: rotate(675deg);
        -o-transform: rotate(675deg);
        transform: rotate(675deg);
    }

    75% {
        -webkit-transform: rotate(810deg);
        -moz-transform: rotate(810deg);
        -ms-transform: rotate(810deg);
        -o-transform: rotate(810deg);
        transform: rotate(810deg);
    }

    87.5% {
        -webkit-transform: rotate(945deg);
        -moz-transform: rotate(945deg);
        -ms-transform: rotate(945deg);
        -o-transform: rotate(945deg);
        transform: rotate(945deg);
    }

    to {
        -webkit-transform: rotate(1080deg);
        -moz-transform: rotate(1080deg);
        -ms-transform: rotate(1080deg);
        -o-transform: rotate(1080deg);
        transform: rotate(1080deg);
    }
}

@-webkit-keyframes ytp-spinner-left-spin {
    0% {
        -webkit-transform: rotate(130deg);
        -moz-transform: rotate(130deg);
        -ms-transform: rotate(130deg);
        -o-transform: rotate(130deg);
        transform: rotate(130deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    to {
        -webkit-transform: rotate(130deg);
        -moz-transform: rotate(130deg);
        -ms-transform: rotate(130deg);
        -o-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@keyframes ytp-spinner-left-spin {
    0% {
        -webkit-transform: rotate(130deg);
        -moz-transform: rotate(130deg);
        -ms-transform: rotate(130deg);
        -o-transform: rotate(130deg);
        transform: rotate(130deg);
    }

    50% {
        -webkit-transform: rotate(-5deg);
        -moz-transform: rotate(-5deg);
        -ms-transform: rotate(-5deg);
        -o-transform: rotate(-5deg);
        transform: rotate(-5deg);
    }

    to {
        -webkit-transform: rotate(130deg);
        -moz-transform: rotate(130deg);
        -ms-transform: rotate(130deg);
        -o-transform: rotate(130deg);
        transform: rotate(130deg);
    }
}

@-webkit-keyframes ytp-right-spin {
    0% {
        -webkit-transform: rotate(-130deg);
        -moz-transform: rotate(-130deg);
        -ms-transform: rotate(-130deg);
        -o-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }

    50% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    to {
        -webkit-transform: rotate(-130deg);
        -moz-transform: rotate(-130deg);
        -ms-transform: rotate(-130deg);
        -o-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

@keyframes ytp-right-spin {
    0% {
        -webkit-transform: rotate(-130deg);
        -moz-transform: rotate(-130deg);
        -ms-transform: rotate(-130deg);
        -o-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }

    50% {
        -webkit-transform: rotate(5deg);
        -moz-transform: rotate(5deg);
        -ms-transform: rotate(5deg);
        -o-transform: rotate(5deg);
        transform: rotate(5deg);
    }

    to {
        -webkit-transform: rotate(-130deg);
        -moz-transform: rotate(-130deg);
        -ms-transform: rotate(-130deg);
        -o-transform: rotate(-130deg);
        transform: rotate(-130deg);
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hpot {
    position: absolute;
    opacity: 0;
    z-index: -1
}

.MuiDialog-paper {
    overflow-x: hidden;
}

.MuiAlert-root.MuiAlert-standardError {
    background: transparent;
}

.theme-light .MuiInputLabel-shrink.MuiInputLabel-outlined {
    background: #fff;
}

.theme-dark .MuiInputLabel-shrink.MuiInputLabel-outlined {
    background: #424242;
}

.MuiInputLabel-shrink.MuiInputLabel-outlined {
    padding: 0 2px;
    background: #fff;
}

.MuiInputLabel-outlined + .MuiInput-underline {
    margin-top: unset;
}

.MuiInputLabel-outlined + .MuiInput-underline:before {
    border-bottom: 0px !important;
}

.MuiFormControl-outlined {
    margin: 8px 0 !important;
}

.Mui-error {
    border-color: #FC5E5E !important;
    color: #FC5E5E !important;
}
